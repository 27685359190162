import * as React from "react";

export default function Waves(props) {
  return (
<svg class="editorial" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none">
<defs> <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />  </defs>
 <g class="parallax1">
  <use href="#gentle-wave" x="50" y="3" fill="#3d4c51"/>
 </g>
   <g class="parallax2">
  <use href="#gentle-wave" x="50" y="0" fill="#34414e"/>
   </g>
     <g class="parallax3">
  <use href="#gentle-wave" x="50" y="9" fill="#36454f"/>
  </g>
   <g class="parallax4">
  <use href="#gentle-wave" x="50" y="6" fill="#fff"/>  
 </g>
</svg>
  );
}
