import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import Videography from '../components/Videography'
import Graphics from '../components/Graphics'
import SocialMedia from '../components/SocialMedia'

import Waves from '../components/Waves'
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';

import Webdev from '../components/Webdev'
import Slide from 'react-reveal/Slide';

import Carousel from '../components/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';



export const IndexPageTemplate = 
({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  services,
}) => (
  
    <div>
 
                    
        <Carousel />
        <Waves />

    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 style={{fontFamily: 'Roboto', fontStyle: 'italic', fontSize: '50px'}}>{mainpitch.title}</h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">{mainpitch.description}</h3>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h1 style={{fontFamily: 'Roboto', fontStyle: 'italic', fontSize: '50px'}}>
                      {heading}
                    </h1>
                    <p>{description}</p>
                  </div>
                </div>
                <div className="container">
        <div className="section">
                <Slide bottom cascade>
                <div className="columns is-multiline">
             
                  <div className="column is-6">
                    <section className="section">
                      <div className="has-text-centered">
                      <h1>Photography & Videography</h1>
                        <hr style={{backgroundColor: '#3d4c51', height: '5px', marginLeft: '7%', width: '150px', border: 'none'}} />
                        <div
                          className="services"
                          style={{
                            width: '240px',
                            display: 'inline-block',
                            
                          }}
                        >
                          
                         <Videography />
                        </div>
                      </div>
                      <p>If you are thinking about the most efficient and effective way of communication, then you need to think about video content.
From business promos to reviewing products, we can cater to your business’ needs. Our video content team will produce ‘scroll stopping’ videos for you.</p>
                    </section>
                  </div>
              
                  <div className="column is-6">
                    <section className="section">
                      <div className="has-text-centered">
                      <h1>Website Design & Development</h1>
                        <hr style={{backgroundColor: '#3d4c51', height: '5px', marginLeft: '3%', width: '150px', border: 'none'}} />

                        <div
                          className="services"
                          style={{
                            width: '240px',
                            display: 'inline-block',
                          }}
                          
                        >
                         <Webdev />
                        </div>
                      </div>
                      <p>Our team has experts in designing and developing websites.
We design the UI of websites so that your clients will have a remarkable journey. BrizzyMedia also offers Search Engine Optimization for clients. You don’t want your hard work to get lost amidst hundreds of pages in Google.</p>
                    </section>
                  </div>
              </div>
              </Slide>
              <Slide bottom cascade>
                <div className="columns is-multiline">
             
                  <div className="column is-6">
                    <section className="section">
                      <div className="has-text-centered">
                      <h1>Graphic Design & Branding</h1>
                        <hr style={{backgroundColor: '#3d4c51', height: '5px', marginLeft: '8%', width: '150px', border: 'none'}} />
                        <div
                          className="services"
                          style={{
                            width: '240px',
                            display: 'inline-block',
                            
                          }}
                        >
                          
                         <Graphics />
                        </div>
                      </div>
                      <p>Our Graphic Design & Branding  team is made up of experts who will add value to your digital footprint.
We truly offer a “Limitless Online Presence”.</p>
                    </section>
                  </div>
              
                  <div className="column is-6">
                    <section className="section">
                      <div className="has-text-centered">
                      <h1>Social Media & Digital Marketing</h1>
                        <hr style={{backgroundColor: '#3d4c51', height: '5px', marginLeft: '1%', width: '150px', border: 'none'}} />

                        <div
                          className="services"
                          style={{
                            width: '240px',
                            display: 'inline-block',
                          }}
                          
                        >
                         <SocialMedia />
                        </div>
                      </div>
                      <p>Online Marketing has been a successful medium for increasing sales and revenues.

Over the last decade, the world of marketing has pivoted towards online services. It is now more important to be available on Amazon, eBay, Social Media and Google. You need to provide 24/7 access to your customers.</p>
                    </section>
                  </div>
              </div>
              </Slide>
                <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/services">
                      See all services
                    </Link>
                  </div>
                </div>
                </div>
                </div>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Latest posts
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
