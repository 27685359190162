import React from "react";
import { Carousel } from "react-responsive-carousel";
import Zoom from 'react-reveal/Zoom';
import Brain from './Brain'
import City from './City'
import Relax from './Relax'


export default () => (
  <Carousel>
        <div className="columns is-desktop is-vcentered" style={{
          backgroundColor: 'white',
          marginBottom: '0px'
        }}>
                <div className="column">
                <svg width="575" height="200" class="text-one">
                  <text class="textone" x="62" y="100" fill="none" stroke="#2C3539" stroke-width="2">ARCHITECT</text>
                  <text class="textone" x="62" y="150" fill="none" stroke="#2C3539" stroke-width="2">RENDERING</text>
                </svg>
                  <h1 class="home-title1">
                  <span>We provide 2D/3D Architectural</span>
                  <span>rendering and video presentations</span>
                  </h1>
                  </div>

                  <div className="column">
                       <City style={{marginTop: '-20%'}} />
                  </div>
                </div>  
  
                <div className="columns is-desktop is-vcentered" style={{
          backgroundColor: 'white',
          marginBottom: '0px'
        }}>
                <div className="column" style={{textAlign: 'center'}}>
                <svg width="575" height="200" class="text-one">
                  <text class="textone" x="60" y="100" fill="none" stroke="#2C3539" stroke-width="2">JUST RELAX</text>
                  <text class="textone" x="60" y="150" fill="none" stroke="#2C3539" stroke-width="2">& SIT BACK</text>
                </svg>
                  <h1 class="home-title1">
                  <span>We will take care of your</span>
                  <span>branding and digital needs</span>
                  </h1>
                  </div>

                  <div className="column">
                       <Relax style={{marginTop: '-20%'}} />
                  </div>
                </div>  
                
  </Carousel>
);
