import * as React from "react";

export default function Relax(props) {
  return (
    <svg width="400" height="285" class="relax" viewBox="0 0 1039 785" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="undraw_a_moment_to_relax_bbpa 1" clip-path="url(#clip0)">
    <g class="stone-two">
    <path id="Vector" d="M267.484 691.979C267.484 691.979 272.484 670.979 307.484 677.979C342.484 684.979 347.484 691.979 347.484 691.979H267.484Z" fill="#71645C"/>
    <path id="Vector_2" d="M387.484 691.979C387.484 691.979 359.484 674.979 347.484 691.979H387.484Z" fill="#71645C"/>
    <path id="Vector_3" d="M256.984 643.979C256.984 643.979 228.984 626.979 216.984 643.979H256.984Z" fill="#71645C"/>
    </g>
    <path class="green-leaf-two" d="M99.9088 628.36C108.245 643.82 125.937 650.456 125.937 650.456C125.937 650.456 130.114 632.027 121.778 616.568C113.442 601.108 95.7493 594.471 95.7493 594.471C95.7493 594.471 91.5726 612.9 99.9088 628.36Z" fill="#456307"/>
    <path class="green-leaf-one" d="M105.638 623.274C120.689 632.328 126.485 650.313 126.485 650.313C126.485 650.313 107.88 653.618 92.8299 644.563C77.7798 635.509 71.9835 617.523 71.9835 617.523C71.9835 617.523 90.5883 614.219 105.638 623.274Z" fill="#C7F563"/>
    <path class="garden-one" d="M851.984 691.979C827.666 688.172 804.285 684.934 781.841 682.265L790.484 664.979C787.484 663.979 774.484 673.979 774.484 673.979L785.484 623.979C770.484 625.979 763.484 676.979 763.484 676.979L746.484 659.979L754.852 679.226C686.171 672.009 626.905 669.757 577.086 670.274L584.484 655.479C581.484 654.479 568.484 664.479 568.484 664.479L579.484 614.479C564.484 616.479 557.484 667.479 557.484 667.479L540.484 650.479L549.347 670.865C515.221 671.856 481.169 674.599 447.324 679.081C454.706 656.085 480.484 633.979 480.484 633.979C460.663 640.145 450.351 650.191 445.014 659.504C443.935 612.26 451.203 565.196 466.484 520.479C466.484 520.479 426.484 610.479 432.484 670.479L433.245 681.137C398.445 686.566 380.984 691.979 380.984 691.979H851.984Z" fill="#456307"/>
    <path class="stone-five" d="M931.984 679.479C931.984 679.479 936.983 658.479 971.983 665.479C1006.98 672.479 1011.98 679.479 1011.98 679.479H931.984Z" fill="#71645C"/>
    <path class="stone-six" d="M590.984 737.479C590.984 737.479 774.984 710.479 888.984 737.479Z" fill="#71645C"/>
    <path class="stone-three" d="M319.984 774.479C319.984 774.479 503.984 747.479 617.984 774.479Z" fill="#71645C"/>
    <path class="stone-four" d="M660.984 784.406C660.984 784.406 769.963 756.768 837.484 784.406Z" fill="#3F3D56"/>
    <g class="plant-right-two">
    <path id="Vector_4" d="M896.216 510.297L894.751 511.66L961.751 583.661L963.216 582.298L896.216 510.297Z" fill="#3F3D56"/>
    <path id="Vector_5" d="M879.484 507.979C885.006 507.979 889.484 503.502 889.484 497.979C889.484 492.456 885.006 487.979 879.484 487.979C873.961 487.979 869.484 492.456 869.484 497.979C869.484 503.502 873.961 507.979 879.484 507.979Z" fill="#EA2C2C"/>
    <path id="Vector_6" d="M923.484 517.979C929.006 517.979 933.484 513.502 933.484 507.979C933.484 502.456 929.006 497.979 923.484 497.979C917.961 497.979 913.484 502.456 913.484 507.979C913.484 513.502 917.961 517.979 923.484 517.979Z" fill="#EA2C2C"/>
    <path id="Vector_7" d="M903.484 556.979C909.006 556.979 913.484 552.502 913.484 546.979C913.484 541.456 909.006 536.979 903.484 536.979C897.961 536.979 893.484 541.456 893.484 546.979C893.484 552.502 897.961 556.979 903.484 556.979Z" fill="#F98686"/>
    </g>
    <g class="plant-right-one">
    <path id="Vector_8" d="M1016.67 589.387L982.681 636.399L984.302 637.571L1018.29 590.559L1016.67 589.387Z" fill="#3F3D56"/>
    <path id="Vector_9" d="M1028.48 581.979C1034.01 581.979 1038.48 577.502 1038.48 571.979C1038.48 566.456 1034.01 561.979 1028.48 561.979C1022.96 561.979 1018.48 566.456 1018.48 571.979C1018.48 577.502 1022.96 581.979 1028.48 581.979Z" fill="#EA2C2C"/>
    <path id="Vector_10" d="M995.484 592.979C1001.01 592.979 1005.48 588.502 1005.48 582.979C1005.48 577.456 1001.01 572.979 995.484 572.979C989.961 572.979 985.484 577.456 985.484 582.979C985.484 588.502 989.961 592.979 995.484 592.979Z" fill="#EA2C2C"/>
    <path id="Vector_11" d="M1018.48 624.979C1024.01 624.979 1028.48 620.502 1028.48 614.979C1028.48 609.456 1024.01 604.979 1018.48 604.979C1012.96 604.979 1008.48 609.456 1008.48 614.979C1008.48 620.502 1012.96 624.979 1018.48 624.979Z" fill="#EA2C2C"/>
    </g>
    <g class="plant-right-three">
    <path id="Vector_12" d="M965.372 673.438L963.595 672.52C986.677 627.896 974.334 569.945 959.916 529.188C950.126 501.793 937.639 475.439 922.638 450.513L924.329 449.445C939.407 474.497 951.959 500.986 961.801 528.52C976.352 569.65 988.781 628.179 965.372 673.438Z" fill="#3F3D56"/>
    <path id="Vector_13" d="M982.491 449.803L969.516 520.808L971.483 521.167L984.458 450.163L982.491 449.803Z" fill="#3F3D56"/>
    <path id="Vector_14" d="M988.484 430.979C994.006 430.979 998.484 426.502 998.484 420.979C998.484 415.456 994.006 410.979 988.484 410.979C982.961 410.979 978.484 415.456 978.484 420.979C978.484 426.502 982.961 430.979 988.484 430.979Z" fill="#F98686"/>
    <path id="Vector_15" d="M913.484 442.979C919.006 442.979 923.484 438.502 923.484 432.979C923.484 427.456 919.006 422.979 913.484 422.979C907.961 422.979 903.484 427.456 903.484 432.979C903.484 438.502 907.961 442.979 913.484 442.979Z" fill="#EA2C2C"/>
    </g>
    <g class="green-tree-one">
    <path id="Vector_16" d="M328.263 379.972C328.263 503.582 254.779 546.742 164.132 546.742C73.4842 546.742 0 503.582 0 379.972C0 256.362 164.132 99.1101 164.132 99.1101C164.132 99.1101 328.263 256.362 328.263 379.972Z" fill="#C7F563"/>
    <path id="Vector_17" d="M158.153 527.833L159.833 424.383L229.79 296.4L160.097 408.154L160.852 361.639L209.067 269.044L161.052 349.329L162.412 265.669L214.04 191.952L162.625 252.514L163.474 99.11L158.138 302.188L158.576 293.811L106.085 213.464L157.735 309.893L152.844 403.327L152.698 400.847L92.185 316.294L152.515 409.608L151.903 421.293L151.794 421.469L151.844 422.428L139.435 659.478H156.014L158.003 537.037L218.185 443.952L158.153 527.833Z" fill="#4E4326"/>
    </g>
    <g class="green-tree-two">
    <path id="Vector_18" d="M869.647 343.122C869.647 494.133 779.873 546.861 669.132 546.861C558.39 546.861 468.616 494.133 468.616 343.122C468.616 192.11 669.132 0 669.132 0C669.132 0 869.647 192.11 869.647 343.122Z" fill="#C7F563"/>
    <path id="Vector_19" d="M661.827 523.761L663.88 397.378L749.345 241.024L664.202 377.552L665.126 320.725L724.028 207.605L665.37 305.687L667.03 203.481L730.104 113.423L667.291 187.409L668.329 0L661.809 248.096L662.345 237.861L598.218 139.704L661.317 257.508L655.341 371.654L655.164 368.625L581.236 265.329L654.94 379.328L654.192 393.603L654.059 393.818L654.12 394.99L638.96 684.589H659.214L661.645 535.005L735.167 421.285L661.827 523.761Z" fill="#4E4326"/>
    </g>
    <path class="relax-left-hand" d="M507.167 560.617L516.304 588.899C516.304 588.899 508.037 615.44 511.953 645.027C515.869 674.614 513.694 675.484 513.694 675.484C513.694 675.484 474.568 673.231 478.483 684.979C482.399 696.727 520.655 689.842 520.655 689.842C520.655 689.842 528.003 687.864 531.483 666.979C534.964 646.094 543.716 605.867 543.716 605.867L535.486 567.893L507.167 560.617Z" fill="#FFB8B8"/>
    <g class="relax-lower-body">
    <path id="Vector_20" d="M648.575 698.544L683.383 697.239L709.925 693.323L702.093 719.429L637.698 721.169L648.575 698.544Z" fill="#FFB8B8"/>
    <path id="Vector_21" d="M698.612 702.025C698.612 702.025 698.177 692.453 694.261 692.888C690.345 693.323 692.956 689.407 696.436 688.537C699.917 687.667 709.925 683.316 709.925 683.316L749.519 685.926C749.519 685.926 775.19 708.551 764.747 720.734C754.305 732.917 727.329 722.04 727.329 722.04L698.177 722.475C698.177 722.475 688.605 706.811 698.612 702.025Z" fill="#2F2E41"/>
    <path id="Vector_22" d="M714.276 668.522L726.893 727.696L753 722.475L738.206 648.507L722.542 640.24L714.276 668.522Z" fill="#FFB8B8"/>
    <path id="Vector_23" d="M564.166 661.125C564.166 661.125 491.069 694.193 521.961 727.696C521.961 727.696 521.961 743.36 554.593 740.314C587.226 737.268 650.316 731.612 653.361 733.352C656.407 735.093 665.979 698.979 657.712 697.239C649.445 695.498 639.438 693.323 639.438 693.323C639.438 693.323 648.14 692.018 648.14 684.621C648.14 684.621 672.941 682.88 680.338 667.217C687.734 651.553 694.696 639.805 694.696 639.805L711.665 683.316C711.665 683.316 731.244 647.202 746.038 663.301C746.038 663.301 729.504 559.312 716.886 549.739C704.268 540.167 695.566 537.122 683.818 545.824C672.071 554.526 661.193 576.716 661.193 576.716L564.166 661.125Z" fill="#18145B"/>
    <path id="Vector_24" d="M738.641 709.857C735.599 712.151 732.694 714.621 729.939 717.253C726.458 720.734 721.672 718.994 721.237 717.253C720.802 715.513 712.97 718.559 712.1 731.177C711.23 743.795 706.009 755.107 719.932 759.023C733.855 762.939 733.42 774.252 733.42 774.252C744.437 779.537 757.08 780.317 768.663 776.427C788.243 769.901 781.281 762.939 781.281 762.939L755.175 719.864C755.175 719.864 748.213 706.811 738.641 709.857Z" fill="#2F2E41"/>
    </g>
    <path class="relax-right-hand2" d="M666.414 494.046C666.414 494.046 708.184 508.405 712.535 511.45C716.886 514.496 736.466 528.854 726.893 536.251C717.321 543.648 709.489 548.434 690.78 542.778C672.071 537.121 651.186 519.717 651.186 519.717L666.414 494.046Z" fill="#FFB8B8"/>
    <g class="relax-upper-body">
    <path id="Vector_25" d="M510.213 502.748C510.213 502.748 493.679 508.84 493.679 525.374C493.679 541.908 499.771 564.533 503.687 565.838C507.602 567.143 533.709 584.983 538.495 572.365C543.281 559.747 510.213 502.748 510.213 502.748Z" fill="#E35302"/>
    <path id="Vector_26" d="M548.502 462.284C548.502 462.284 548.937 479.253 547.197 480.993C545.456 482.734 568.081 534.076 592.882 511.45C617.683 488.825 598.539 479.688 598.539 479.688C598.539 479.688 589.401 462.719 589.837 460.979C590.272 459.238 549.372 460.979 549.372 460.979L548.502 462.284Z" fill="#FFB8B8"/>
    <path id="Vector_27" d="M624.645 474.902C624.645 474.902 641.179 471.856 652.926 478.383C664.674 484.909 674.681 497.962 674.681 497.962C674.681 497.962 663.804 521.023 655.972 524.503C648.14 527.984 633.782 510.58 633.782 510.58L624.645 474.902Z" fill="#E35302"/>
    <path id="Vector_28" opacity="0.1" d="M548.502 462.284C548.502 462.284 548.937 479.253 547.197 480.993C545.456 482.734 568.081 534.076 592.882 511.45C617.683 488.825 598.539 479.688 598.539 479.688C598.539 479.688 589.401 462.719 589.837 460.979C590.272 459.238 549.372 460.979 549.372 460.979L548.502 462.284Z" fill="black"/>
    <path id="Vector_29" d="M555.464 494.917C555.464 494.917 571.997 506.229 578.959 503.619C585.921 501.008 594.623 489.695 595.493 488.825C596.363 487.955 640.743 577.151 640.743 577.151C640.743 577.151 634.217 609.783 616.813 619.356C599.409 628.928 599.626 636.542 597.451 643.069C595.275 649.595 567.211 622.401 567.211 622.401L549.372 546.694V500.573L555.464 494.917Z" fill="#E35302"/>
    <path id="Vector_30" d="M548.284 474.684C548.284 474.684 542.846 473.597 537.189 478.383C531.533 483.169 504.992 501.008 506.297 502.748C507.602 504.489 519.35 529.29 521.961 539.297C524.571 549.304 528.487 550.61 528.487 550.61C528.487 550.61 537.624 557.136 536.319 561.922C535.014 566.708 529.358 631.974 537.189 646.332C545.021 660.69 543.716 673.308 541.54 676.789C539.365 680.27 562.425 666.782 562.425 666.782C562.425 666.782 596.798 638.935 599.409 630.668C601.097 624.985 602.262 619.16 602.89 613.264C602.89 613.264 580.699 525.374 573.738 520.588C566.776 515.802 548.284 474.684 548.284 474.684Z" fill="#2B2B2E"/>
    <path id="Vector_31" d="M596.084 475.029C596.084 475.029 606.806 468.375 608.981 470.116C611.157 471.856 631.606 471.856 632.041 473.597C632.476 475.337 633.347 517.107 648.14 527.984C662.934 538.862 653.361 560.617 653.361 560.617L664.239 578.021C664.239 578.021 605.066 635.015 608.981 615.44C609.199 614.352 608.981 614.134 608.981 614.134C608.981 614.134 585.921 515.366 588.966 510.145C592.012 504.924 597.546 481.246 596.084 475.029Z" fill="#2B2B2E"/>
    </g>
    <path class="relax-right-hand1" d="M698.299 514.931L639.125 535.381C639.125 535.381 580.387 549.304 589.524 565.403C598.661 581.502 637.385 554.09 637.385 554.09L716.185 545.261C716.185 545.261 747.466 518.847 698.299 514.931Z" fill="#FFB8B8"/>
    <g class="relax-head">
    <path id="Vector_32" d="M571.997 478.383C589.78 478.383 604.195 463.967 604.195 446.185C604.195 428.403 589.78 413.988 571.997 413.988C554.215 413.988 539.8 428.403 539.8 446.185C539.8 463.967 554.215 478.383 571.997 478.383Z" fill="#FFB8B8"/>
    <path id="Vector_33" d="M546.356 414.187L540.401 412.021C540.401 412.021 552.854 399.568 570.18 400.651L565.307 395.778C565.307 395.778 577.219 391.446 588.047 402.816C593.74 408.793 600.326 415.819 604.432 423.733H610.81L608.148 429.058L617.465 434.382L607.902 433.425C608.808 438.022 608.496 442.776 606.998 447.214L604.832 453.17C604.832 453.17 596.169 435.844 596.169 433.678V439.093C596.169 439.093 590.213 434.22 590.213 430.971L586.964 434.761L585.34 428.805L565.307 434.761L568.555 429.888L556.102 431.513L560.975 425.557C560.975 425.557 546.898 432.596 546.356 438.551C545.815 444.507 538.776 452.087 538.776 452.087L535.528 446.673C535.528 446.673 530.655 422.308 546.356 414.187Z" fill="#5F4A14"/>
    </g>
    <path class="stone-one" d="M94.9835 664.479C94.9835 664.479 99.9835 643.479 134.983 650.479C169.983 657.479 174.983 664.479 174.983 664.479H94.9835Z" fill="#71645C"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="1038.48" height="784.406" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  );
}
